import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import socket from './socket';
import './styles/styleHome.css';

function Home() {
    const [playerName, setPlayerName] = useState('');
    const [gameId, setGameId] = useState('');
    const navigate = useNavigate();
    const [nbPlayersParam, setnbPlayersParam] = useState("3");

    const createGame = () => {
        const id = Math.random().toString(36).substring(2, 6).toUpperCase();
        localStorage.setItem('playerName', playerName);
        socket.emit('create_game', { gameId: id, playerName, nbPlayersParam});
        navigate(`/lobby/${id}`);
    };

    const joinGame = () => {
        localStorage.setItem('playerName', playerName);
        navigate(`/lobby/${gameId}`);
    };

    const handleChange = (event) => {
        setnbPlayersParam(event.target.value); 
    };

    return (
        <div className='home'>

            <div className='nameContainer'>
                <h1 className='homeH1'>Rejoindre une partie de Tripley :</h1>
                <p>Pseudo :</p>
                <input
                    className='name homeInput'
                    type="text"
                    placeholder="Votre pseudo"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                />
            </div>
            <div className='forms'>
                <div className='formCreateGame'>
                    <select id="numbers" name="numbers"  value={nbPlayersParam} onChange={handleChange} className='selectNbPlayers'>
                        <option value="3">3 joueurs</option>
                        <option value="4">4 joueurs</option>
                        <option value="5">5 joueurs</option>
                        <option value="6">6 joueurs</option>
                    </select>
                    <button onClick={createGame}>Créer une partie</button>
                </div>
                <div className='formJoinGame'>
                    <input
                        type="text"
                        placeholder="ID de la partie"
                        value={gameId}
                        onChange={(e) => setGameId(e.target.value)}
                        className='homeInput'
                    />
                    <button onClick={joinGame}>Rejoindre une partie</button>
                </div>

            </div>
        </div>
    );
}

export default Home;
