import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import socket from './socket';
import CartesContainer from './components/cartesContainer';
import Carte from './components/carte';
import CartesCentralesContainer from './components/cartesCentralesContainer';
import CartesAdversaireContainer from './components/cartesAdversaireContainer';
import './styles/styleGame.css';
import './styles/styleTrios.css';

function Game() {
    const { gameId } = useParams();
    const location = useLocation();
    const [gameState, setGameState] = useState(location.state || null);
    const [revealedCards, setRevealedCards] = useState([]);

    const [canPlay, setCanPlay] = useState(1);

    const getClassName = (num) => {
        switch (num) {
            case 1:
                return 'bg-1';
            case 2:
                return 'bg-2';
            case 3:
                return 'bg-3';
            case 4:
                return 'bg-4';
            case 5:
                return 'bg-5';
            case 6:
                return 'bg-6';
            case 7:
                return 'bg-7';
            case 8:
                return 'bg-8';
            case 9:
                return 'bg-9';
            case 10:
                return 'bg-10';
            case 11:
                return 'bg-11';
            case 12:
                return 'bg-12';
            default:
                return 'bg-default';
        }
    };

    useEffect(() => {
        if (!gameState) {
            socket.emit('join_game', { gameId });
        }

        socket.on('card_revealed', ({ card, playerId }) => {
            console.log('Card revealed:', { card, playerId });
            setRevealedCards((prev) => [...prev, { value: card, playerId }]);
        });

        socket.on('game_over', ({ winner }) => {
            alert(`Partie terminée ! Le gagnant est ${winner.name}`);
            setGameState(null);
        });

        return () => {
            socket.off('card_revealed');
            socket.off('game_over');
        };
    }, [gameId, gameState]);

    useEffect(() => {
        socket.on('end_turn', (updatedGame) => {
            setGameState(updatedGame);
            // Les cartes révélées restent visibles jusqu'à ce que le serveur émette 'update_game'
        });

        socket.on('update_game', (updatedGame) => {
            console.log("OMG UPDATE GAME")
            console.log(center);
            setGameState(updatedGame);
        });

        socket.on('update_can_play', (updatedCanPlay) => {
            console.log("updateCanPlay")
            setCanPlay(updatedCanPlay);
        });

        return () => {
            socket.off('end_turn');
            socket.off('update_game');
        };
    }, [socket]);

    useEffect(() => {
        socket.on('update_game_centre', (updatedGame) => {
            console.log('Mise à jour reçue par le client :', JSON.stringify(updatedGame, null, 2));
            setGameState(updatedGame); // Met à jour l'état local
        });

        return () => {
            socket.off('update_game'); // Nettoyer l'écouteur
        };
    }, [socket]);



    if (!gameState) return <p>Chargement...</p>;

    const players = gameState.players || [];
    const center = gameState.center || [];
    const currentPlayerIndex = gameState.currentPlayerIndex ?? -1;
    const currentPlayer = players.find((p) => p.id === socket.id) || {};

    const playTurn = (action, targetPlayerId, centerCardId) => {
        socket.emit('play_turn', { gameId, action, targetPlayerId, centerCardId });
    };

    let imPlaying = false;
    if (players[currentPlayerIndex] == currentPlayer) {
        imPlaying = true;
    }

    console.log(players);
    console.log(currentPlayer);
    console.log('Cé le cenctre :', JSON.stringify(gameState.center, null, 2));

    const isFirstOrLast = (index, size) => {
        if(index == 0 && size != 3){
            return 'First';
        }
        else if (index == size-2 && size != 3){
            return 'Last';
        }
        else if(index == 0 && size == 3){
            return 'DecaleGauche';
        }
        else if (index == size-2 && size == 3){
            return 'DecaleDroit';
        }
    }

    const isUpper3players = (size) => {
        if(size == 3){
            return 'isUpper';
        }
    }

    const isUpper4players = (size) => {
        if(size == 4){
            return 'isUpper4players'
        }
    }

    return (
        <div className='game'>
            
            <div className="game-container">
                {/* Adversaires */}
                <div className='adversairesContainer'>
                    {players
                        .filter((player) => player.id !== currentPlayer.id)
                        .map((adversaire, index) => (
                            <div key={adversaire.id} className="adversaire top">
                                <CartesAdversaireContainer
                                    adversaire={adversaire}
                                    socket={socket}
                                    gameId={gameId}
                                    isCurrentPlayer={players[currentPlayerIndex]?.id === currentPlayer.id}
                                    canPlay={canPlay}
                                    trios={adversaire.trios}
                                    position={isFirstOrLast(index, players.length)}
                                    isUpper={isUpper4players(players.length)}
                                />
                            </div>
                        ))}
                </div>
                <p className='tourDesc'>Au tour de : <br />{players[currentPlayerIndex].name}</p>

                {/* Cartes au centre */}
                <div className="cartes-centrales">
                    <CartesCentralesContainer
                        socket={socket}
                        center={center}
                        gameId={gameId}
                        currentPlayer={currentPlayer}
                        currentPlayerIndex={currentPlayerIndex}
                        players={players}
                        canPlay={canPlay}
                    />
                </div>

                {/* Main du joueur */}
                <div className="main-joueur">
                    <CartesContainer
                        currentPlayer={currentPlayer}
                        playTurn={playTurn}
                        player={currentPlayer}
                        canPlay={canPlay}
                        isUpper={isUpper3players(players.length)}
                    />
                    <div className='trios-joueur'>
                        {currentPlayer.trios.map((trio, index) => (
                            <div key={index} className="trio-icon">
                                <div className="trio-stack">
                                    <div className={`trio-bottom bigger ${getClassName(trio)}`}></div>
                                    <div className={`trio-middle bigger ${getClassName(trio)}`}></div>
                                    <div className={`trio-top bigger ${getClassName(trio)}`}>{trio}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Game;
