import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Lobby from './Lobby';
import Game from './Game';
import Header from './components/header.js';

import './App.css';
import Carte from './components/carte.js';
import CartesContainer from './components/cartesContainer.js';

function App() {
    return (
        <div>
            <Header />
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/lobby/:gameId" element={<Lobby />} />
                    <Route path="/game/:gameId" element={<Game />} />
                </Routes>
            </Router>
            <div className='version'>PRE-ALPHA V0.2</div>
            
        </div>
    );
}

export default App;
