import '../styles/styleCarte.css';

const getClassName = (num) => {
    switch (num) {
        case 1:
            return 'bg-1';
        case 2:
            return 'bg-2';
        case 3:
            return 'bg-3';
        case 4:
            return 'bg-4';
        case 5:
            return 'bg-5';
        case 6:
            return 'bg-6';
        case 7:
            return 'bg-7';
        case 8:
            return 'bg-8';
        case 9:
            return 'bg-9';
        case 10:
            return 'bg-10';
        case 11:
            return 'bg-11';
        case 12:
            return 'bg-12';
        default:
            return 'bg-default';
    }
};

const getClassNameIsRevealed = (isRevealed, className) => {
    if(isRevealed == 1){
        return 'isRevealed';
    }
    else {
        return className + 'Hover'
    }
}
export default function Carte({ chiffre, className, playTurn, player, isSmallest, isLargest, canPlay, isRevealed }) {
    const handleClick = () => {
        if (isSmallest) {
            playTurn({ type: 'ask_card', direction: 'smallest' }, player.id);
        } else if (isLargest) {
            playTurn({ type: 'ask_card', direction: 'largest' }, player.id);
        }
    };



    console.log("isRevealed : " + isRevealed);

    return (
        <div
            className={`CarteHover ${className} ${getClassNameIsRevealed(isRevealed, className)}`}
            onClick={canPlay == 1 ? isSmallest || isLargest ? handleClick : undefined : null}
        >
            <div className={`Carte ${getClassName(chiffre)}`}>
                <p>{chiffre}</p>
                <h1>{chiffre}</h1>
            </div>
        </div>
    );
}
