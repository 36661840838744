import { useState, useEffect } from 'react';
import '../styles/styleCarteCentrale.css';
import '../styles/styleCarte.css';

export default function CarteCentrale({ value, socket, gameId, currentPlayer, card, isCurrentPlayer, revealed , canPlay}) {
    const [isFlipped, setIsFlipped] = useState(revealed); // Initialisation basée sur `revealed`

    // Synchroniser `isFlipped` avec la prop `revealed`
    useEffect(() => {
        setIsFlipped(revealed); // Met à jour l'état local quand `revealed` change
    }, [revealed]);

    const handleFlip = () => {
        if (isCurrentPlayer && !revealed) {
            socket.emit('play_turn', { gameId, action: { type: 'reveal_center' }, centerCardId: card.id });
        }
    };

    const getClassName = (num) => {
        switch (num) {
            case 1: return 'bg-1';
            case 2: return 'bg-2';
            case 3: return 'bg-3';
            case 4: return 'bg-4';
            case 5: return 'bg-5';
            case 6: return 'bg-6';
            case 7: return 'bg-7';
            case 8: return 'bg-8';
            case 9: return 'bg-9';
            case 10: return 'bg-10';
            case 11: return 'bg-11';
            case 12: return 'bg-12';
            default: return 'bg-default';
        }
    };

    console.log(card);

    return (
        <div className="CarteCentrale">
            <div
                className={`card-container ${isFlipped ? 'flipped' : ''}`}
                onClick={isCurrentPlayer && !revealed && canPlay == 1 ? handleFlip : undefined}
                style={{ pointerEvents: isCurrentPlayer && !revealed ? 'auto' : 'none' }}
            >
                <div className="card">
                    <div className={`card-front ${getClassName(value)}`}>{value}</div>
                    <div className="card-back">TRIPLEY</div>
                </div>
            </div>
        </div>
    );
}
