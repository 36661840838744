import React from 'react';
import '../styles/styleCarteAdversaire.css'; // Assurez-vous que ce fichier contient vos styles

export default function CarteAdversaire({ value, revealed }) {
    const getClassName = (num) => {
        switch (num) {
            case 1: return 'bg-1';
            case 2: return 'bg-2';
            case 3: return 'bg-3';
            case 4: return 'bg-4';
            case 5: return 'bg-5';
            case 6: return 'bg-6';
            case 7: return 'bg-7';
            case 8: return 'bg-8';
            case 9: return 'bg-9';
            case 10: return 'bg-10';
            case 11: return 'bg-11';
            case 12: return 'bg-12';
            default: return 'bg-default';
        }
    };
    return (
        <div className={`carte-adversaire ${revealed == 0 ? 'flipped' : 'visible'}`}>
            <div className="cardAdv">
                <div className={`cardAdv-front ${getClassName(value)}`}>{value}</div>
                <div className="cardAdv-back">TRIPLEY</div>
            </div>
        </div>
    );
}
