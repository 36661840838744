import React from 'react';
import '../styles/styleTrios.css';
import '../styles/styleCartesAdversaireContainer.css';
import CarteAdversaire from './carteAdversaire';

export default function CartesAdversaireContainer({ adversaire, socket, gameId, isCurrentPlayer, canPlay, trios, position, isUpper }) {
    const revealCard = (direction) => {
        socket.emit('play_turn', {
            gameId,
            action: { type: 'ask_card', direction },
            targetPlayerId: adversaire.id,
        });
    };

    const areBtnVisible = (isCurrentPlayer) => {
        if (isCurrentPlayer == 0) {
            return 'hideBtn';
        }
    };

    const getClassName = (num) => {
        switch (num) {
            case 1:
                return 'bg-1';
            case 2:
                return 'bg-2';
            case 3:
                return 'bg-3';
            case 4:
                return 'bg-4';
            case 5:
                return 'bg-5';
            case 6:
                return 'bg-6';
            case 7:
                return 'bg-7';
            case 8:
                return 'bg-8';
            case 9:
                return 'bg-9';
            case 10:
                return 'bg-10';
            case 11:
                return 'bg-11';
            case 12:
                return 'bg-12';
            default:
                return 'bg-default';
        }
    };

    const nbCards = (nbOfCards) => {
        if(nbOfCards == 0){
            return "nocards";
        }
    }

    return (
        <div className={`cartes-adversaire-container ${'position' + position} ${isUpper}`}>
            <div className="adversaire-header">
                <div className='nameAndTriosContainer'>
                    <p className='name'>{adversaire.name}</p>
                </div>
                <div className='buttonsContainer'>
                    <button
                        onClick={canPlay == 1 ? () => revealCard('smallest') : null}
                        disabled={!isCurrentPlayer}
                        className={`smallest ${areBtnVisible(isCurrentPlayer)}`}
                    >
                        -
                    </button>
                    <button
                        onClick={canPlay == 1 ? () => revealCard('largest') : null}
                        disabled={!isCurrentPlayer}
                        className={`largest ${areBtnVisible(isCurrentPlayer)}`}
                    >
                        +
                    </button>
                </div>
            </div>

            <div className='cartes-adversaire-et-trios'>

                <div className={`cartes-adversaire`}>
                    {adversaire.hand.map((card, index) => (
                        <CarteAdversaire
                            key={index}
                            value={card}
                            revealed={adversaire.revealedCards[index]}
                        />
                    ))}
                </div>
                <div className={`trios-adversaire-container ${nbCards(adversaire.hand.length)}`}>
                    <div className="trios-container">
                        {trios.map((trio, index) => (
                            <div key={index} className="trio-icon">
                                <div className="trio-stack">
                                    <div className={`trio-bottom ${getClassName(trio)}`}></div>
                                    <div className={`trio-middle ${getClassName(trio)}`}></div>
                                    <div className={`trio-top ${getClassName(trio)}`}>{trio}</div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

            </div>


        </div>
    );
}
