import CarteCentrale from './carteCentrale';
import '../styles/styleCartesCentralesContainer.css';

export default function CartesCentralesContainer({ center, socket, gameId, currentPlayer, currentPlayerIndex, players, canPlay }) {
    const isCurrentPlayer = players[currentPlayerIndex]?.id === currentPlayer.id;

    return (
        <div className="cartesCentralesContainer">
            {center.map((card) => (
                <CarteCentrale
                    key={card.id}
                    value={card.value}
                    className="CarteCentrale"
                    socket={socket}
                    gameId={gameId}
                    currentPlayer={currentPlayer}
                    card={card}
                    isCurrentPlayer={isCurrentPlayer}
                    revealed={card.revealed} 
                    canPlay={canPlay}
                />
            ))}
        </div>
    );
}
