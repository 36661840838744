import '../styles/styleCartesContainer.css';
import Carte from './carte';

export default function CartesContainer({ currentPlayer, playTurn, player, canPlay, isUpper }) {
    if (!currentPlayer || !currentPlayer.hand) {
        console.log('currentPlayer ou hand est indéfini:', currentPlayer);
        return <p>Pas de cartes disponibles</p>;
    }

    // Trouver la plus petite carte non révélée
    const smallestIndex = currentPlayer.hand.findIndex(
        (card, index) => !currentPlayer.revealedCards[index] &&
            index === currentPlayer.revealedCards.findIndex((revealed, i) => !revealed && i >= index)
    );

    // Trouver la plus grande carte non révélée
    const largestIndex = currentPlayer.hand
        .map((card, index) => ({ card, index }))
        .filter(({ index }) => !currentPlayer.revealedCards[index])
        .sort((a, b) => b.index - a.index)[0]?.index;

    const getClassName = (index, totalCards) => {
        const midPoint = Math.floor(totalCards / 2);

        if (totalCards % 2 !== 0 && index === midPoint) {
            // Si le nombre total de cartes est impair, la carte du centre a 'slotCentre'
            return 'slotCentre';
        } else if (index < midPoint) {
            // Cartes à gauche
            return `slotGauche${midPoint - index}`;
        } else if (index > midPoint && totalCards % 2 !== 0){
            // Cartes à droite
            return `slotDroite${index - midPoint}`;
        }
        else {
            return `slotDroite${index - midPoint + 1}`;
        }
    };


    return (
        <div className={`CartesContainer ${isUpper}`}>
            {currentPlayer.hand.length > 0 ? (
                currentPlayer.hand.map((card, index) => (
                    <Carte
                        key={index}
                        chiffre={card}
                        className={getClassName(index, currentPlayer.hand.length)}
                        playTurn={playTurn}
                        player={player}
                        isSmallest={index === smallestIndex} // Détermine si c'est la plus petite non révélée
                        isLargest={index === largestIndex} // Détermine si c'est la plus grande non révélée
                        canPlay={canPlay}
                        isRevealed={currentPlayer.revealedCards[index]} // Vérifie si la carte est révélée
                    />
                ))
            ) : (
                <p className='noCards'>Plus de cartes</p>
            )}
        </div>
    );
}
